<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">分销员名称</span>
          <el-input
            class="searchInput"
            placeholder="分销员名称"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.queryStr"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">提现状态</span>
          <el-select class="searchInput" v-model="searchValue.applyStatus" placeholder="审核状态">
            <el-option label="全部" value=""> </el-option>
            <el-option label="审核中" :value="0"> </el-option>
            <el-option label="已提现" :value="1"> </el-option>
            <el-option label="已拒绝" :value="2"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <span class="searchText">手机号</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="手机号"
            @keyup.enter.native="getList"
            v-model="searchValue.phoneNumber"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">申请时间</span>
          <el-date-picker
            v-model="date"
            type="datetimerange"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row class="query">
        <el-col :span="8" :offset="16">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>
      <el-table
        :data="list"
        v-loading="loading"
        style="width: 100%"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="id" label="ID"> </el-table-column>
        <el-table-column align="center" prop="info" label="头像">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.avatarUrl | thumbSmall" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nickname" label="用户名"> </el-table-column>
        <el-table-column align="center" prop="applyAmount" label="本次申请收益"> </el-table-column>
        <el-table-column align="center" prop="currentTotalAmount" label="可提现收益"> </el-table-column>
        <el-table-column align="center" prop="currentTotalAmount" label="剩余可提现金额">
          <template slot-scope="scope">
            {{ (scope.row.currentTotalAmount - scope.row.applyAmount).toFixed(2) }}
          </template></el-table-column
        >
        <el-table-column align="center" prop="phoneNumber" label="状态">
          <template slot-scope="scope">
            {{ scope.row.applyStatus | applyStatus }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="申请时间">
          <template slot-scope="scope">
            {{ scope.row.applyTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="到账时间">
          <template slot-scope="scope">
            {{ scope.row.arrivedTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.applyStatus === 0" @click="approveWithdraw(scope.row)" type="text" size="small">
              通过</el-button
            >
            <el-button v-if="scope.row.applyStatus === 0" @click="showReject(scope.row)" type="text" size="small">
              拒绝</el-button
            >
            <el-button
              v-if="scope.row.applyStatus === 2"
              @click="checkRejectReason(scope.row)"
              type="text"
              size="small"
            >
              查看拒绝原因</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="Selection">
          <el-button type="info" @click="toggleSelection(list)">反选</el-button>
          <el-button type="primary" @click="batchApproveWithdraw">批量通过</el-button>
          <el-button type="primary" @click="batchRejectWithdraw">批量拒绝</el-button>
        </div>
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
        <div></div>
      </div>
    </el-card>

    <!-- 查看拒绝原因  -->
    <el-dialog :close-on-click-modal="false" title="拒绝原因" :visible.sync="rejectReasonDialog" width="350px">
      {{ rejectReasonText }}
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectReasonDialog = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 输入拒绝原因 -->

    <el-dialog :close-on-click-modal="false" title="拒绝原因" :visible.sync="rejectDialog" width="350px">
      <el-input
        type="textarea"
        maxlength="30"
        show-word-limit
        placeholder="请输入拒绝原因"
        v-model.trim="rejectReason"
        clearable
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialog = false">取 消</el-button>
        <el-button type="primary" @click="rejectWithdraw">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="batchApproveDialog" width="350px">
      是否批量同意提现审核
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchApproveDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmBatchApproveWithdraw">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      batchApproveDialog: false,
      blockContext: '',
      rejectReason: '',
      orderTime: [],
      searchValue: {
        applyStatus: '',
        queryStr: '',
        phoneNumber: '',
        applyStartTime: '',
        applyEndTime: ''
      },
      rejectReasonDialog: false,
      rejectDialog: false,
      rejectReasonText: '',
      list: [],
      memberId: '',
      ids: [],
      isBatch: false,
      date: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
    }
  },

  methods: {
    batchRejectWithdraw() {
      if (this.ids.length === 0) {
        this.$message.error('请先选择要操作的提现审核')
        return
      }

      this.isBatch = true
      this.rejectReason = ''
      this.rejectDialog = true
    },
    batchApproveWithdraw() {
      if (this.ids.length === 0) {
        this.$message.error('请先选择要操作的提现审核')
        return
      }

      this.batchApproveDialog = true
    },
    confirmBatchApproveWithdraw() {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$api.distribution
        .distribution('batchApproveWithdraw', { ids: this.ids })
        .then(res => {
          this.$message.success('审核通过')
          this.batchApproveDialog = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.ids.splice(0)
      this.ids = val.map(i => {
        return i.id
      })
    },
    // 请求后端获取提现审核列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      if (this.date && this.date.length !== 0) {
        this.searchValue.applyStartTime = this.date[0]
        this.searchValue.applyEndTime = this.date[1]
      } else {
        this.searchValue.applyStartTime = ''
        this.searchValue.applyEndTime = ''
      }
      const cloneData = JSON.parse(JSON.stringify(this.searchValue))
      if (this.searchValue.applyStartTime === '') {
        Reflect.deleteProperty(cloneData, 'applyStartTime')
        Reflect.deleteProperty(cloneData, 'applyEndTime')
      }
      let params = {
        params: {
          ...cloneData,
          page: this.currentPage,
          size: this.pageSize
        }
      }
      console.log('搜索条件')
      console.log(cloneData)
      this.$api.distribution
        .distribution('distributionWithdrawList', params)
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkRejectReason(row) {
      this.rejectReasonDialog = true
      this.rejectReasonText = row.rejectReason
    },
    approveWithdraw(row) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$api.distribution
        .distribution('approveWithdraw', row.id)
        .then(res => {
          this.$message.success('审核通过')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },

    showReject(row) {
      this.rejectDialog = true
      this.memberId = row.id
      this.isBatch = false
    },
    rejectWithdraw() {
      if (this.rejectReason === '') {
        this.$message.error('请输入拒绝原因')
        return
      }

      if (this.isBatch) {
        const loading = this.$loading({
          lock: true,
          text: '正在提交，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$api.distribution
          .distribution('batchRejectWithdraw', { ids: this.ids, rejectReason: this.rejectReason })
          .then(res => {
            this.getList()
            this.rejectReason = ''
            this.rejectDialog = false
            this.$message.success('您已拒绝提现')
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      } else {
        const loading = this.$loading({
          lock: true,
          text: '正在提交，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$api.distribution
          .distribution('rejectWithdraw', { id: this.memberId, rejectReason: this.rejectReason })
          .then(res => {
            this.getList()
            this.rejectReason = ''
            this.rejectDialog = false
            this.$message.success('您已拒绝提现')
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      }
    },

    resetSearch() {
      this.date = [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      this.searchValue = {
        applyStatus: '',
        queryStr: '',
        phoneNumber: '',
        applyStartTime: '',
        applyEndTime: ''
      }
      this.date = []
      this.getList()
    }
  },
  filters: {
    applyStatus(val) {
      switch (val) {
        case 0:
          return '审核中'
          break
        case 1:
          return '已提现'
          break
        default:
          return '已拒绝'
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.platform-data {
  display: flex;
}

.searchContainer .searchInput {
  width: 180px;
}

.searchContainer .searchInputRange {
  width: 80px;
}
.query {
  margin-top: 20px;
}
.avatar {
  width: 50px;
  height: 50px;
}
.tableContainer .footer {
  justify-content: space-between;
}
</style>
